import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../client/client';
import SliderNotasCat from './SliderNotasCat';
import NewspaperSection from './NewspaperSection';
import FullNews from './FullNews';

const News = () => {
    const { id } = useParams();
    const [categoryData, setCategoryData] = useState(null);
    const [newsData, setNewsData] = useState(null);
    const [filteredNews, setFilteredNews] = useState([]);

    useEffect(() => {
        const fetchNewsData = async () => {
            const { data, error } = await supabase
                .from('news')
                .select('*')
                .eq('id', id)
                .single();

            if (!error) {
                setNewsData(data);
                fetchCategoryData(data.category_id);
                fetchAllNews(data.category_id, data.id);
            } else {
                console.error("Error fetching news:", error);
            }
        };

        fetchNewsData();
    }, [id]);

    const fetchCategoryData = async (categoryId) => {
        const { data: categoryData, error } = await supabase
            .from('categorias')
            .select('*')
            .eq('id', categoryId);

        if (!error && categoryData.length > 0) {
            setCategoryData(categoryData[0]);
        } else {
            console.error("Error fetching category data:", error);
        }
    };

    const fetchAllNews = async (categoryId, mainNewsId) => {
        const { data, error } = await supabase
            .from('news')
            .select('*')
            .eq('category_id', categoryId)
            .order('created_at', { ascending: false });

        if (!error) {
            const filtered = data.filter(news => news.id !== mainNewsId);
            setFilteredNews(filtered);
        } else {
            console.error("Error fetching all news:", error);
        }
    };

    const calculateBrightness = (hexColor) => {
        if (!hexColor) return 0;
        const color = hexColor.replace('#', '');

        const r = parseInt(color.substring(0, 2), 16);
        const g = parseInt(color.substring(2, 4), 16);
        const b = parseInt(color.substring(4, 6), 16);

        return (r * 0.299 + g * 0.587 + b * 0.114);
    };

    const isLightBackground = (hexColor) => {
        return calculateBrightness(hexColor) > 186;
    };

    const renderBody = () => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = newsData.body;
        return tempDiv.innerText;
    };

    return (
        <div>
            {newsData ? (
                <>
                    <div>
                        <div className="class justify-center">
                            {categoryData && (
                                <div
                                    className="p-2"
                                    style={{
                                        backgroundColor: categoryData.color || '#000',
                                        color: isLightBackground(categoryData.color) ? '#000' : '#fff',
                                        textAlign: 'center',
                                    }}
                                >
                                    {categoryData.name}
                                </div>
                            )}
                            {newsData && newsData.id && categoryData ? (  // Asegurarse de que categoryData esté disponible
                                <FullNews
                                    id={newsData.id}
                                    title={newsData.title}
                                    subtitle={newsData.written_by}
                                    image={newsData.image_url || "https://via.placeholder.com/300"}
                                    text={newsData.body}
                                    color={categoryData.color}
                                />
                            ) : (
                                <p>Cargando noticia o categoría...</p>
                            )}

                            {filteredNews.length > 0 ? (
                                <div className="mt-100">
                                    <h1 style={{ color: categoryData?.color || '#000' }}>Otras noticias</h1>
                                    <SliderNotasCat filteredNews={filteredNews} />
                                </div>
                            ) : (
                                <p>No hay noticias recientes en esta categoría.</p>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <p>Cargando noticia...</p>
            )}
        </div>
    );
};

export default News;
