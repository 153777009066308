import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../client/client';
import NewspaperSection from './NewspaperSection';
import SliderNotasCat from './SliderNotasCat';
import Accordion from './Accordion';

const Categorias = () => {
  const text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...";
  const { id } = useParams();
  const [categoryData, setCategoryData] = useState(null);
  const [latestNews, setLatestNews] = useState(null);
  const [filteredNews, setFilteredNews] = useState([]);

  useEffect(() => {
    const fetchCategoryData = async () => {
      const { data, error } = await supabase
        .from('categorias')
        .select('*')
        .eq('id', id);

      if (!error) {
        setCategoryData(data[0]);
      } else {
        console.error("Error fetching category data:", error);
      }
    };

    fetchCategoryData();
  }, [id]);

  useEffect(() => {
    const fetchLatestNews = async () => {
      const { data, error } = await supabase
        .from('news')
        .select('*')
        .eq('category_id', id)
        .eq('number', 1)
        .order('created_at', { ascending: false })
        .limit(1);

      if (!error && data.length > 0) {
        setLatestNews(data[0]);
      } else if (error) {
        console.error("Error fetching latest news:", error);
      }
    };

    fetchLatestNews();
  }, [id]);

  useEffect(() => {
    const fetchAllNews = async () => {
      const { data, error } = await supabase
        .from('news')
        .select('*')
        .eq('category_id', id)
        .order('created_at', { ascending: false });

      if (!error) {
        setFilteredNews(data);
      } else {
        console.error("Error fetching all news:", error);
      }
    };

    fetchAllNews();
  }, [id]);

  if (!categoryData) {
    return <div>Cargando categoría...</div>;
  }

  return (
    <div>
      <div className="class justify-center">
        <h1 className='title uppercase' style={{ color: categoryData.color }}>{categoryData.name}</h1>

        {id === '6' && <Accordion />}

        {latestNews ? (
          <NewspaperSection
            id={latestNews.id}
            title={latestNews.title}
            image={latestNews.image_url || "https://via.placeholder.com/300"}
            text={latestNews.body || text}
            color={categoryData.color}
            subtitle={latestNews.written_by}
          />
        ) : (
          <p>No hay noticias recientes en esta categoría.</p>
        )}

        <div className="mt-100">
          <h1 style={{ color: categoryData.color }}>Otras noticias</h1>
          <SliderNotasCat filteredNews={filteredNews} />
        </div>
      </div>
    </div>
  );
};

export default Categorias;
