import React, { useState, useEffect } from 'react';
import { supabase } from '../client/client';

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [answersMap, setAnswersMap] = useState({});

  useEffect(() => {
    const fetchQuestions = async () => {
      const { data, error } = await supabase
        .from('questions')
        .select('question, id')
        .order('created_at', { ascending: false });

      if (!error) {
        setQuestions(data);
        // Cargar las respuestas para cada pregunta
        data.forEach(question => {
          fetchAnswers(question.id);
        });
      } else {
        console.error("Error fetching questions:", error);
      }
    };

    const fetchAnswers = async (questionId) => {
      const { data, error } = await supabase
        .from('answers')
        .select('*')
        .eq('question_id', questionId)
        .order('created_at', { ascending: false });

      if (!error) {
        setAnswersMap(prev => ({
          ...prev,
          [questionId]: data
        }));
      } else {
        console.error("Error fetching answers:", error);
      }
    };

    fetchQuestions();
  }, []);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {questions.map((question, index) => (
        <div key={question.id} className="accordion-item">
          <div className="accordion-title" onClick={() => handleClick(index)}>
            <span dangerouslySetInnerHTML={{ __html: question.question }} />
            <span className={`accordion-icon ${activeIndex === index ? 'active' : ''}`}>
              {activeIndex === index ? '▼' : '▲'}
            </span>
          </div>
          <div className={`accordion-content ${activeIndex === index ? 'active' : ''}`}>
            {activeIndex === index && (
              <>
                {answersMap[question.id]?.map((answer) => (
                  <div key={answer.id} className="accordion-answer flex items-center mb-4">
                    <img src={answer.image_url} alt={answer.answer} className="accordion-image mr-10" />
                    <div className="accordion-text">
                      <p className="accordion-name">{answer.written_by}</p>
                      <p className="accordion-p">{answer.answer}</p>
                    </div>
                  </div>
                )) || <p>No hay respuestas disponibles.</p>}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
