import React, { useEffect, useState } from 'react'
import NewspaperLayout from './NewspaperLayout'
import NewspaperLayoutInv from './NewspaperLayoutInv'
import { supabase } from '../client/client';

const Noticias = () => {
    const [newsData, setNewsData] = useState([]);
    const [newsDataInv, setNewsDataInv] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [categoriesDataInv, setCategoriesDataInv] = useState([]);
    const [newsCategory1, setNewsCategory1] = useState([]);
    const [newsCategory3, setNewsCategory3] = useState([]);
    const [newsCategory5, setNewsCategory5] = useState([]);
    const [newsCategory2, setNewsCategory2] = useState([]);
    const [newsCategory4, setNewsCategory4] = useState([]);

    useEffect(() => {
        const fetchNewsAndCategories = async () => {
            // Obtener noticias
            const { data: news, error: newsError } = await supabase
                .from('news')
                .select('*');

            // Manejar errores
            if (newsError) {
                console.error('Error fetching news:', newsError);
            } else {
                setNewsData(news);
            }

            // Obtener categorías
            const { data: categories, error: categoriesError } = await supabase
                .from('categorias')
                .select('*');

            if (categoriesError) {
                console.error('Error fetching categories:', categoriesError);
            } else {
                setCategoriesData(categories);
            }

            // Filtrar las noticias por category_id
            const filteredNewsCategory1 = news.filter(n => n.category_id === 1);
            const filteredNewsCategory3 = news.filter(n => n.category_id === 3);
            const filteredNewsCategory5 = news.filter(n => n.category_id === 5);

            // Guardar las noticias filtradas en los estados correspondientes
            setNewsCategory1(filteredNewsCategory1);
            setNewsCategory3(filteredNewsCategory3);
            setNewsCategory5(filteredNewsCategory5);
        };

        fetchNewsAndCategories();
    }, []);

    useEffect(() => {
        const fetchNewsAndCategoriesInv = async () => {
            // Obtener noticias
            const { data: news, error: newsError } = await supabase
                .from('news')
                .select('*');

            // Manejar errores
            if (newsError) {
                console.error('Error fetching news:', newsError);
            } else {
                setNewsDataInv(news);
            }

            // Obtener categorías
            const { data: categories, error: categoriesError } = await supabase
                .from('categorias')
                .select('*');

            if (categoriesError) {
                console.error('Error fetching categories:', categoriesError);
            } else {
                setCategoriesDataInv(categories);
            }

            // Filtrar las noticias por category_id
            const filteredNewsCategory2 = news.filter(n => n.category_id === 2);
            const filteredNewsCategory4 = news.filter(n => n.category_id === 4);

            // Guardar las noticias filtradas en los estados correspondientes
            setNewsCategory2(filteredNewsCategory2);
            setNewsCategory4(filteredNewsCategory4);
        };

        fetchNewsAndCategoriesInv();
    }, []);

    return (
        <div className="newspaper-layout">
            {/* Cada llamado a NewspaperLayout con las noticias filtradas por categoría */}
            <NewspaperLayout newsData={newsCategory1} categoriesData={categoriesData} />
            <NewspaperLayoutInv newsData={newsCategory2} categoriesData={categoriesData} />
            <NewspaperLayout newsData={newsCategory3} categoriesData={categoriesData} />
            <NewspaperLayoutInv newsData={newsCategory4} categoriesData={categoriesData} />
            <NewspaperLayout newsData={newsCategory5} categoriesData={categoriesData} />
        </div>
    )
}

export default Noticias