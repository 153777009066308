import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { Link } from 'react-router-dom';

function SliderNotas({ newsData, categoriesData }) {
    const filteredNews = categoriesData.map(category => {
        return newsData.find(news => news.category_id === category.id && news.number === 1);
    }).filter(Boolean);

    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={30}
            slidesPerView={4}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            touchRatio={1.5}
            grabCursor={true}
            breakpoints={{
                300: {
                    slidesPerView: 1,
                },
                480: {
                    slidesPerView: 2,
                },
                640: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 4,
                },
                1024: {
                    slidesPerView: 5,
                },
            }}
        >
            {filteredNews.map(news => (
                <SwiperSlide key={news.id}>
                    <Link to={`/noticias/${news.id}`}>
                    <img src={news.image_url} alt={news.title} style={{ width: "100%" }} />
                    <h2 className="slider-text_notas">{news.title}</h2>
                    <p className='mb-10'>{news.body.length > 100 ? `${news.body.substring(0, 100)}...` : news.body}</p>
                    </Link>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export default SliderNotas;
