import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const NewspaperLayout = ({ newsData, categoriesData }) => {
    const [mainNews, setMainNews] = useState(null);
    const [rightColumns, setRightColumns] = useState({ two: null, three: null });
    const [imageStyles, setImageStyles] = useState({});
    const [categoryStyles, setCategoryStyles] = useState({ line: '', category: '', button: '' });
    const [categoryName, setCategoryName] = useState('');
    const [textColor, setTextColor] = useState('#000');

    useEffect(() => {
        const getLatestNewsByNumber = (numberValue) => {
            const filteredNews = newsData
                ?.filter(n => n.number === numberValue)
                ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            return filteredNews?.[0] || null;
        };

        const mainNews = getLatestNewsByNumber(1);
        setMainNews(mainNews);

        const newsWithNumberTwo = getLatestNewsByNumber(2);
        const newsWithNumberThree = getLatestNewsByNumber(3);
        setRightColumns({ two: newsWithNumberTwo, three: newsWithNumberThree });

        const getCategoryColorAndName = (newsItem) => {
            const category = categoriesData?.find(cat => cat.id === newsItem?.category_id);
            return category ? { color: category.color, name: category.name } : { color: '#000', name: '' };
        };

        if (mainNews) {
            const { color, name } = getCategoryColorAndName(mainNews);
            setCategoryStyles({
                line: color,
                category: color,
                button: color,
            });
            setCategoryName(name);

            const isLightColor = (color) => {
                const rgb = color.match(/\w\w/g).map(x => parseInt(x, 16));
                const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
                return brightness > 128;
            };

            setTextColor(isLightColor(color) ? '#000' : '#fff');
        }

    }, [newsData, categoriesData]);

    const handleImageLoad = (event, id) => {
        const img = event.target;
        if (img.naturalWidth === img.naturalHeight) {
            setImageStyles(prevStyles => ({
                ...prevStyles,
                [id]: { width: '70%', height: '70%' }
            }));
        }
    };

    const getFirstParagraph = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        const firstParagraph = tempDiv.querySelector('p');

        return firstParagraph ? firstParagraph.innerHTML : tempDiv.innerHTML;
    };

    return (
        <>
            <div className='line_space'>
                <hr className='line' style={{ borderColor: categoryStyles.line }} />
            </div>

            <div className='news_category' style={{ backgroundColor: categoryStyles.category, color: textColor }}>
                {categoryName || 'Categoría'}
            </div>

            <div className="columns-container-principal">
                {mainNews && (
                    <div className="main-news">
                        <Link to={`/noticias/${mainNews.id}`}>
                            <img
                                src={mainNews.image_url}
                                alt={mainNews.title}
                                style={imageStyles['main']}
                                onLoad={(e) => handleImageLoad(e, 'main')}
                            />
                            <h2>{mainNews.title}</h2>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: getFirstParagraph(mainNews.body)
                                }}
                            />


                            <div className="columns-container">
                                <Link to={`/noticias/${mainNews.id}`} target='_blank'>
                                    <div className='button_categorias' style={{ backgroundColor: categoryStyles.button, color: textColor }}>
                                        Ver más
                                    </div>
                                </Link>
                            </div>
                        </Link>
                    </div>
                )}

                <div className="right-column">
                    {rightColumns.two && (
                        <div className="column-right">
                            <Link to={`/noticias/${rightColumns.two.id}`}>
                                <img
                                    src={rightColumns.two.image_url}
                                    alt={`Noticia 2`}
                                    style={imageStyles['two']}
                                    onLoad={(e) => handleImageLoad(e, 'two')}
                                />
                                <h2>{rightColumns.two.title}</h2>
                                <p>{rightColumns.two.body.length > 100 ? `${rightColumns.two.body.substring(0, 100)}...` : rightColumns.two.body}</p>
                                <div className="columns-container">
                                    <Link to={`/noticias/${rightColumns.two.id}`} target='_blank'>
                                        <div className='button_categorias' style={{ backgroundColor: categoryStyles.button, color: textColor }}>
                                            Ver más
                                        </div>
                                    </Link>
                                </div>
                            </Link>
                        </div>
                    )}
                    {rightColumns.three && (
                        <div className="column-right">
                            <Link to={`/noticias/${rightColumns.three.id}`}>
                                <img
                                    src={rightColumns.three.image_url}
                                    alt={`Noticia 3`}
                                    style={imageStyles['three']}
                                    onLoad={(e) => handleImageLoad(e, 'three')}
                                />
                                <h2>{rightColumns.three.title}</h2>
                                <p>{rightColumns.three.body.length > 100 ? `${rightColumns.three.body.substring(0, 100)}...` : rightColumns.three.body}</p>
                                <div className="columns-container">
                                    <Link to={`/noticias/${rightColumns.three.id}`} target='_blank'>
                                        <div className='button_categorias' style={{ backgroundColor: categoryStyles.button, color: textColor }}>
                                            Ver más
                                        </div>
                                    </Link>
                                </div>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default NewspaperLayout;
