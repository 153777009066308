import React from 'react';

const Historia = () => {
  return (
    <div className="class justify-center">

      <h1 className='title p-[100px]'>Comité Editorial</h1>
      <div className="history justify-center">
        <div className="card_image_history">
        </div>
      </div>
      <div className="column_nosotros gap-4">
        <div className="column_nosotros">
          <div>
            <p className='nombre justify-self-center'>Broswi Gálvez Villafuerte</p>
            <p className='flex justify-center'>Director Editorial</p>
          </div>
        </div>
      </div>
      <div className="column_nosotros gap-4">
        <div className="column_nosotros">
          <div>
            <p className='nombre justify-self-center'>Gabriela Alosilla Martínez</p>
            <p className='flex justify-center'>Edición General</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center">
        <div className="column_nosotros w-full sm:w-1/2 md:w-1/4 p-4">
          <div>
            <p className='nombre text-center'>Salomé Galdos Cruz</p>
            <p className='flex justify-center'>Comité Editorial</p>
          </div>
        </div>
        <div className="column_nosotros w-full sm:w-1/2 md:w-1/4 p-4">
          <div>
            <p className='nombre text-center'>Jorge Sosa Torres</p>
            <p className='flex justify-center'>Comité Editorial</p>
          </div>
        </div>
        <div className="column_nosotros w-full sm:w-1/2 md:w-1/4 p-4">
          <div>
            <p className='nombre text-center'>Ludovina Villanueva Núñez</p>
            <p className='flex justify-center'>Comité Editorial</p>
          </div>
        </div>
        <div className="column_nosotros w-full sm:w-1/2 md:w-1/4 p-4">
          <div>
            <p className='nombre text-center'>Héctor Mayhuire Rodríguez</p>
            <p className='flex justify-center'>Comité Editorial</p>
          </div>
        </div>
      </div>

      <div className="column_nosotros_two gap-4">

        <div className="column_nosotros">
          <div>
            <p className='nombre justify-self-center'>Elizabeht Bautista Toledano</p>
            <p className='flex justify-center'>Corrección de estilo</p>
          </div>
        </div>
      </div>
      <div className="column_nosotros_two gap-4">

        <div className="column_nosotros">
          <div>
            <p className='nombre justify-self-center'>Giancarlo Salinas Naiza</p>
            <p className='flex justify-center'>Diagramación</p>
          </div>
        </div>
      </div>
      <div className="column_nosotros_two gap-4">

        <div className="column_nosotros">
          <div>
            <p className='nombre justify-self-center'>Comunicación Integral y Servicios Generales E. I. R. L.</p>
            <p className='flex justify-center'>Publicidad</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Historia;
