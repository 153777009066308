import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { Link } from 'react-router-dom';

const SliderNotasCat = ({ filteredNews }) => {

    const getFirstParagraph = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        // Buscar el primer <p>
        const firstParagraph = tempDiv.querySelector('p');

        // Si no hay <p>, retornar todo el HTML como un fallback
        return firstParagraph ? firstParagraph.innerHTML : tempDiv.innerHTML;
    };

    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={30}
            slidesPerView={4}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            touchRatio={1.5}
            grabCursor={true}
            breakpoints={{
                300: {
                    slidesPerView: 1,
                },
                480: {
                    slidesPerView: 2,
                },
                640: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 4,
                },
                1024: {
                    slidesPerView: 5,
                },
            }}
        >
            {filteredNews.map(news => (
                <SwiperSlide key={news.id}>
                    <div className='space_slides'>
                    <Link to={`/noticias/${news.id}`}>
                        <img src={news.image_url} alt={news.title} className="slider-image" />
                        <h2 className="slider-text_notas">{news.title}</h2>
                        <p
                            className="slider-text_notas_p"
                            dangerouslySetInnerHTML={{
                                __html: getFirstParagraph(news.body)
                            }}
                        />
                    </Link>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default SliderNotasCat;
