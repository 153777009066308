import React, { useState, useEffect } from 'react';

const CustomAd = ({ ads, interval = 3000 }) => {
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  useEffect(() => {
    const adInterval = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
    }, interval);

    return () => clearInterval(adInterval);
  }, [ads.length, interval]);

  const currentAd = ads[currentAdIndex];

  return (
    <div style={{ padding: '0px', margin: '0px 0', position: 'relative' }}>
      <a href={currentAd.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <img src={currentAd.image_url} alt={currentAd.title} style={{ width: '100%' }} />
        <h2>{currentAd.title}</h2>
        <p>{currentAd.description}</p>
      </a>
    </div>
  );
};

export default CustomAd;
