import { Link } from "react-router-dom";

const   FullNews = ({ id, title, image, text, color, subtitle }) => {


  return (
    <Link to={`/noticias/${id}`}>
      <div className="newspaper-section" style={{ backgroundColor: 'transparent' }}>
        <h1 className="title" style={{ color: color }}>{title}</h1>
        <h2 className="subtitle" style={{ color: 'black' }}>Escrito por: <span style={{ color: 'black' }}>{subtitle}</span></h2>
        <div className="content">
          <img src={image} alt="newspaper" className="image" />
          <div dangerouslySetInnerHTML={{ __html: text }} className="text"></div>
        </div>
      </div>
    </Link>

  );
};

export default FullNews;