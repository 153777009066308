import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';


function Slider() {
    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={10}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            breakpoints={{
                300: {
                    slidesPerView: 1,
                },
                320: {
                    slidesPerView: 1,
                },
                480: {
                    slidesPerView: 2,
                },
                640: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 4,
                },
                1024: {
                    slidesPerView: 8,
                },
            }}
        >
            <SwiperSlide>
                <a href="https://www.camara-arequipa.org.pe/erpccia/public/archivos/revistas/CCIA-revista%20septiembre-2024-simples.pdf" target="_blank" >
                    <img src="/revista41.jpg" alt="" style={{ width: "20vh" }} />
                    <h2 className="slider-text">Revista 41</h2>
                </a>
            </SwiperSlide>
            <SwiperSlide>
                <a href="https://www.camara-arequipa.org.pe/public/archivos/revistas/CCIA-revista%20mayo-2024-web.pdf" target="_blank" >
                    <img src="/revista_40anios.jpg" alt="" style={{ width: "20vh" }} />
                    <h2 className="slider-text">Revista 40</h2>
                </a>
            </SwiperSlide>
            <SwiperSlide>
                <a href="https://www.camara-arequipa.org.pe/erpccia/public/archivos/revistas/Revista-CCIA-marzo-2024.pdf" target="_blank" >
                    <img src="/revista39.jpg" alt="" style={{ width: "20vh" }} />
                    <h2 className="slider-text">Revista 39</h2>
                </a>
            </SwiperSlide>
            <SwiperSlide>
                <a href="https://www.camara-arequipa.org.pe/erpccia/public/archivos/revistas/CCIA-2023-diciembre.pdf" target="_blank" >
                    <img src="/revista38.jpg" alt="" style={{ width: "20vh" }} />
                    <h2 className="slider-text">Revista 38</h2>
                </a>
            </SwiperSlide>
            <SwiperSlide>
                <a href="https://www.camara-arequipa.org.pe/erpccia/public/archivos/revistas/revista_setiembre_2023.pdf" target="_blank" >
                    <img src="/revista37.jpg" alt="" style={{ width: "20vh" }} />
                    <h2 className="slider-text">Revista 37</h2>
                </a>
            </SwiperSlide>
            <SwiperSlide>
                <a href="https://www.camara-arequipa.org.pe/erpccia/public/archivos/revistas/Revista_CCIA_julio_2023.pdf" target="_blank" >
                    <img src="/revista36.jpg" alt="" style={{ width: "20vh" }} />
                    <h2 className="slider-text">Revista 36</h2>
                </a>
            </SwiperSlide>
            <SwiperSlide>
                <a href="https://www.camara-arequipa.org.pe/erpccia/public/archivos/revistas/Revista_CCIA_julio_2023.pdf" target="_blank" >
                    <img src="/revista1.jpg" alt="" style={{ width: "20vh" }} />
                    <h2 className="slider-text">Revista 1</h2>
                </a>
            </SwiperSlide>
            
            
            {/* ... */}
        </Swiper>
    )
}

export default Slider
