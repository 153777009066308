import React, { useEffect, useState } from 'react';
import Slider from './Slider';
import CustomAd from './CustomAd';
import Popup from './Popup';
import SliderNotas from './SliderNotas';
import { supabase } from "../client/client";
import NewspaperLayout from './NewspaperLayout';
import NewspaperLayoutInv from './NewspaperLayoutInv';

const Home = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [newsData, setNewsData] = useState([]);
  const [newsDataInv, setNewsDataInv] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [categoriesDataInv, setCategoriesDataInv] = useState([]);
  const [publicityData1, setPublicityData1] = useState(null);
  const [publicityData2, setPublicityData2] = useState(null);

  const [adsNumber1, setAdsNumber1] = useState([]);
  const [adsNumber2, setAdsNumber2] = useState([]);

  const [newsCategory1, setNewsCategory1] = useState([]);
  const [newsCategory3, setNewsCategory3] = useState([]);
  const [newsCategory5, setNewsCategory5] = useState([]);
  const [newsCategory2, setNewsCategory2] = useState([]);
  const [newsCategory4, setNewsCategory4] = useState([]);

  useEffect(() => {
    const fetchNewsAndCategories = async () => {
      // Obtener noticias
      const { data: news, error: newsError } = await supabase
        .from('news')
        .select('*');

      // Manejar errores
      if (newsError) {
        console.error('Error fetching news:', newsError);
      } else {
        setNewsData(news);
      }

      // Obtener categorías
      const { data: categories, error: categoriesError } = await supabase
        .from('categorias')
        .select('*');

      if (categoriesError) {
        console.error('Error fetching categories:', categoriesError);
      } else {
        setCategoriesData(categories);
      }

      // Filtrar las noticias por category_id
      const filteredNewsCategory1 = news.filter(n => n.category_id === 1);
      const filteredNewsCategory3 = news.filter(n => n.category_id === 3);
      const filteredNewsCategory5 = news.filter(n => n.category_id === 5);

      // Guardar las noticias filtradas en los estados correspondientes
      setNewsCategory1(filteredNewsCategory1);
      setNewsCategory3(filteredNewsCategory3);
      setNewsCategory5(filteredNewsCategory5);
    };

    const fetchPublicityData = async () => {
      const { data: publicity, error: publicityError } = await supabase
        .from('publicity')
        .select('*');

      if (publicityError) {
        console.error('Error fetching publicity:', publicityError);
      } else {
        // Filtrar los anuncios por número
        const adsWithNumber1 = publicity.filter(ad => ad.number === 1);
        const adsWithNumber2 = publicity.filter(ad => ad.number === 2);

        // Guardar los datos en el estado
        setAdsNumber1(adsWithNumber1);
        setAdsNumber2(adsWithNumber2);
      }
    };

    fetchNewsAndCategories();
    fetchPublicityData();
  }, []);

  useEffect(() => {
    const fetchNewsAndCategoriesInv = async () => {
      // Obtener noticias
      const { data: news, error: newsError } = await supabase
        .from('news')
        .select('*');

      // Manejar errores
      if (newsError) {
        console.error('Error fetching news:', newsError);
      } else {
        setNewsDataInv(news);
      }

      // Obtener categorías
      const { data: categories, error: categoriesError } = await supabase
        .from('categorias')
        .select('*');

      if (categoriesError) {
        console.error('Error fetching categories:', categoriesError);
      } else {
        setCategoriesDataInv(categories);
      }

      // Filtrar las noticias por category_id
      const filteredNewsCategory2 = news.filter(n => n.category_id === 2);
      const filteredNewsCategory4 = news.filter(n => n.category_id === 4);

      // Guardar las noticias filtradas en los estados correspondientes
      setNewsCategory2(filteredNewsCategory2);
      setNewsCategory4(filteredNewsCategory4);
    };

    fetchNewsAndCategoriesInv();
  }, []);


  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && <Popup closePopup={closePopup} />}

      <div className="class_home">
        <div className="ad-container">
          <div className="custom-ad-wrapper">
            {adsNumber1.length > 0 && (
              <CustomAd
                ads={adsNumber1}
                interval={5000}
              />
            )}
          </div>
        </div>

        <div className="w-[100%]">
          <div className='slider_notas'>
            <SliderNotas newsData={newsData} categoriesData={categoriesData} />
          </div>



          <div className="newspaper-layout">
            {/* Cada llamado a NewspaperLayout con las noticias filtradas por categoría */}
            <NewspaperLayout newsData={newsCategory1} categoriesData={categoriesData} />
            <NewspaperLayoutInv newsData={newsCategory2} categoriesData={categoriesData} />
            <NewspaperLayout newsData={newsCategory3} categoriesData={categoriesData} />
            <NewspaperLayoutInv newsData={newsCategory4} categoriesData={categoriesData} />
            <NewspaperLayout newsData={newsCategory5} categoriesData={categoriesData} />

            <div className="ad-container">
              <div className="custom-ad-wrapper">
                {adsNumber2.length > 0 && (
                  <CustomAd
                    ads={adsNumber2}
                    interval={5000}
                  />
                )}
              </div>
            </div>
            <h1 className='title'>Revista CÁMARA AQP</h1>
            <a href="https://www.camara-arequipa.org.pe/revista-institucional" target='_blank'>
              <div className='button_revista'>Ver todas las revistas</div>
            </a>
          </div>

          <Slider />
        </div>
      </div>
    </>
  );
};

export default Home;
